import "./App.css";
import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import colaborotors from "./assets/colaborotors.webp";
import leftrightcolaborotors from "./assets/left-rigtcolaborotors.webp";
import leftcolaborotors from "./assets/leftcolaborotors.webp";
import rightcolaborotors from "./assets/rightcolaborotors.webp";
import AreaDetecter from "./assets/AreaDetecter.webp";
import Portfolio from "./portfolio";
import Marquee from "react-fast-marquee";
import arvrclient from "./arvrclient.json";
import arvr from "./assets/ARVR.webp";
import { scrollToTop } from "./App";
import { Link } from "react-router-dom";
import ARDevlopmentFaq from "./ARDevlopmentFaq";
import marquee from "./merquee.json";

function ARVR() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const technology = [
    {
      name: "Augmented Reality",
      tech: [
        {
          images: "../assets/tech/unity.webp",
          name: "Unity",
        },
        {
          images: "../assets/tech/ar-kit.png",
          name: "ARKit",
        },
        {
          images: "../assets/tech/ar-core.svg",
          name: "ARCore",
        },
        {
          images: "../assets/tech/vuforia.png",
          name: "Vuforia",
        },
        {
          images: "../assets/tech/sparkar.svg",
          name: "Spark AR",
        },
        {
          images: "../assets/tech/zappar.png",
          name: "Zappar",
        },
      ],
    },
    {
      name: "Virtual Reality",
      tech: [
        {
          images: "../assets/tech/oculus.webp",
          name: "Open VR",
        },
        {
          images: "../assets/tech/unity.webp",
          name: "Unity",
        },
        {
          images: "../assets/tech/webvr.png",
          name: "Web VR",
        },
        {
          images: "../assets/tech/unity.webp",
          name: "Unity 3D",
        },
        {
          images: "../assets/tech/unreal-engine.svg",
          name: "Unreal Engine",
        },
      ],
    },
    {
      name: "Cross-Platform Solutions",
      tech: [
        {
          images: "../assets/tech/webvr.png",
          name: "WebAR",
        },
        {
          images: "../assets/tech/react.webp",
          name: "native apps for iOS and Android",
        },
      ],
    },
  ];

  return (
    <div className="App ARVR">
      <MetaTags>
        <meta
          name="description"
          content="Unlock new realities with Arccus' innovative AR/VR app development solutions. Our creative experts are here to transform user experiences in virtual worlds!"
        />
        <meta
          name="keyword"
          content="ar vr app development, ar vr development company"
        />
        <meta
          property="og:title"
          content="AR VR App Development Company | Arccus Inc"
        />
        <link rel="canonical" href="https://arccusinc.com/ar-vr-development" />
        <title>Leading AR/VR App Development Services | Arccus Inc</title>
      </MetaTags>

      <section className="ARVR-banner banner-sec ">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>Top AR VR App Development Services</h1>
              <p>
                Pioneering immersive experiences with augmented and virtual
                reality technology.
              </p>
              <Link to="/contact-us" className="quote">
                Request A Quote
              </Link>
            </div>
            <div className="col-md-6">
              <img src={arvr} alt="banner-img" />
            </div>
          </div>
        </div>
        <div className="bannergredient1"></div>
        <div className="bannergredient2"></div>
        <div className="bannergredient3"></div>
        <div className="bannergredient4"></div>
      </section>

      <section className="development-service">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="text-start pb-0">
                #1 Innovative AR/VR App Development Company in USA
              </h2>
            </div>
            <div className="col-md-6 Collaborators">
              <p>
                Arccus Inc., is a digital service provider specialize in
                delivering cutting-edge AR VR app development services helping
                organizations revolutionize customer experiences and operations.
                As a noble AR/VR app development company, we design mesmeric
                digital environments customized as per your needs. Whether
                you’re looking to build an engaging AR marketing campaign or a
                VR training simulator, our expert team brings your vision to
                life with solutions tailored.
              </p>
              <p>
                Our services cover diverse industries, delivering innovative AR
                Development and VR Development services that drive business
                success. We aim to help brands associate with their consumers
                strongly with the power of immersive technology.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="Collaborators position-relative">
        <div className="container">
          <h2 className="pt-5">Our Collaborators and Allies</h2>
          <div className="text-center">
            <img src={colaborotors} alt="colaborotors" />
          </div>
          <div className="row colaborotors-detail align-items-center">
            <div className="col-md-6 ">
              <div className="position-relative width-fit-content overflow-hidden">
                <img src={leftrightcolaborotors} alt="virtual-reality" />
                <div className="leftcolaborotors position-absolute">
                  <img src={leftcolaborotors} alt="virtual-reality" />
                </div>
                <div className="rightcolaborotors position-absolute">
                  <img src={rightcolaborotors} alt="virtual-reality" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="text-start p-0">
                Enter a New Reality with Our VR Solutions
              </h2>
              <p>
                Unlock Limitless Possibilities with Arrcus VR Technology -
                Explore, Learn, and Create in a New Reality.
              </p>
              <a
                rel="noreferrer"
                href="https://www.arccusinc.com/future-of-virtual-reality-market-trends-and-challenges"
                target="_blank"
              >
                <button className=" quote-arvr">Know More</button>
              </a>
            </div>
          </div>
          <div class="bannergredient1"></div>
        </div>
      </section>

      <section className="Collaborators">
        <div className="container">
          <div className="row colaborotors-detail align-items-center">
            <div className="col-md-6">
              <h2 className="text-start p-0">
                Enter a New Reality with Our AR Solutions
              </h2>
              <p>
                Unlock Limitless Possibilities with Arrcus VR Technology -
                Explore, Learn, and Create in a New Reality.
              </p>
              <a
                rel="noreferrer"
                href="https://arccusinc.com/future-of-augmented-reality-trends"
                target="_blank"
              >
                <button className=" quote-arvr">Know More</button>
              </a>
            </div>
            <div className="col-md-6 position-relative text-center">
              <div className="AreaDetecter position-absolute">
                <img src={AreaDetecter} alt="area-detector" />
              </div>
              <model-viewer
                src="./assets/MetaverseGirl.gltf"
                seamless-poster
                environment-image="neutral"
                shadow-intensity="1"
                autoplay
                ar
                ar-modes="webxr scene-viewer quick-look"
                disable-zoom
                camera-controls
                auto-rotate
                interaction-prompt-threshold="100"
                enable-pan
                disable-tap
                min-camera-orbit="auto 80deg auto"
                max-camera-orbit="auto 80deg auto"
                camera-orbit="-337.2deg 95deg 3.878m"
                field-of-view="30deg"
              ></model-viewer>
            </div>
          </div>
        </div>
      </section>

      <section className="Collaborators porfolio-slider position-relative">
        <div className="container">
          <h2 className="p-0">
            "AR/VR App Development Services Offered By Us"
          </h2>
          {/* <p className="p-0 text-center">
            Discover the diverse range of clients we serve and the solutions we
            provide to meet their needs
          </p> */}

          <div className="portfolio-section">
            <Portfolio />
          </div>
        </div>
        <div className="bannergredient4"></div>
        <div className="bannergredient3"></div>
      </section>

      <section className="Collaborators">
        <div className="container">
        <h2 className="p-0">
            Our Proven Path for AR VR Excellence
          </h2>
          <p className="p-0 text-center">
          At Arccus Inc., we follow a structured process to ensure the success of every AR and VR project. Here’s how we transform your ideas into immersive experiences:
          </p>
          <ul className="timeline p-0">
            <li className="timeline-event">
              <label className="timeline-event-icon"></label>
              <div className="timeline-event-copy">
                <h3 className="timeline-event-thumbnail">Consultation and Discovery</h3>
                <p>
                We begin by understanding your vision, goals, and target audience. Through in-depth discussions, we gather insights to tailor our approach specifically to your needs.
                </p>
              </div>
            </li>
            <li className="timeline-event">
              <label className="timeline-event-icon"></label>
              <div className="timeline-event-copy">
                <h3 className="timeline-event-thumbnail">
                Concept Development
                </h3>
                <p>
                Our team collaborates to brainstorm and develop creative concepts. We outline key features, functionalities, and the overall user experience, ensuring alignment with your objectives.
                </p>
              </div>
            </li>
            <li className="timeline-event">
              <label className="timeline-event-icon"></label>
              <div className="timeline-event-copy">
                <h3 className="timeline-event-thumbnail">Design and Prototyping</h3>
                <p>
                We create high-fidelity designs and interactive prototypes to visualize the experience. This stage allows for feedback and refinement before moving into development.
                </p>
              </div>
            </li>
            <li className="timeline-event">
              <label className="timeline-event-icon"></label>
              <div className="timeline-event-copy">
                <h3 className="timeline-event-thumbnail">Development</h3>
                <p>
                Utilizing the latest technologies, we bring your project to life. Our skilled developers build robust AR and VR applications that are scalable, efficient, and optimized for performance.
                </p>
              </div>
            </li>
            <li className="timeline-event">
              <label className="timeline-event-icon"></label>
              <div className="timeline-event-copy">
                <h3 className="timeline-event-thumbnail">Testing and Quality Assurance</h3>
                <p>
                Rigorous testing ensures that every aspect of the application functions flawlessly. We conduct usability testing, bug fixes, and performance assessments to guarantee a seamless user experience.
                </p>
              </div>
            </li>
            <li className="timeline-event">
              <label className="timeline-event-icon"></label>
              <div className="timeline-event-copy">
                <h3 className="timeline-event-thumbnail">Deployment</h3>
                <p>
                Once approved, we deploy the application across your desired platforms, ensuring it’s accessible to your target audience.
                </p>
              </div>
            </li>
            <li className="timeline-event">
              <label className="timeline-event-icon"></label>
              <div className="timeline-event-copy">
                <h3 className="timeline-event-thumbnail">Support and Iteration</h3>
                <p>
                Our commitment doesn’t end at launch. We provide ongoing support and updates, incorporating user feedback to enhance the experience and keep your application relevant.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="valued-client position-relative">
        <div className="container">
          <h2 className="p-0">Praise from Our Valued Clients</h2>
          <p className="text-center">
            Discover the diverse range of clients we serve and the solutions we
            provide to meet their needs
          </p>
        </div>

        <Marquee gradient={false} pauseOnHover={true} speed={75}>
          {arvrclient?.map((client) => {
            return (
              <div className="ARVR-client">
                <div className="client-detail">
                  <div className="client-feedback">
                    <p>{client.clientFeedback}</p>
                  </div>
                  <ul className="arvrclient-detail p-0">
                    <li>
                      <ul>
                        <li className="client-name">{client.clientName}</li>
                        <li className="client-occupation">
                          {client.clientOccupation}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            );
          })}
        </Marquee>
        <div className="bannergredient3"></div>
      </section>

      <section className="technology">
        <div className="marquee d-block">
          <div className="container Collaborators">
            <h2 className="mb-3 pb-0">Technologies We Used</h2>
            <p className="text-center">
              At Arccus Inc., we believe that AR and VR solutions are only as
              powerful as the technology behind them. That’s why our development
              is supported by cutting-edge tools and software development kits
              that enable us to create extraordinary AR and VR experiences.
            </p>
          </div>
          <Marquee gradient={false} pauseOnHover={true} speed={75}>
            {technology.map((tech, ind) => {
              return (
                <div className="technology-slide ">
                  <div className="tech-head">
                    <h6>{tech.name}</h6>
                  </div>
                  <div className="technology-row">
                    {tech.tech.map((technology) => {
                      return (
                        <div className="technology-pan">
                          <img
                            src={technology.images}
                            alt={"technology"}
                            loading="lazy"
                          />
                          <h6>{technology.name}</h6>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Marquee>
        </div>
      </section>

      <section className="industries">
        <h2>AR/VR App Solutions Across Several Industry Verticals</h2>
        <div className="marquee d-block">
          {marquee.map((data, ind) => {
            return (
              <Marquee
                direction={ind === 0 ? "left" : "right"}
                gradient={false}
                pauseOnHover={true}
              >
                {data.data.map((marquee) => {
                  return (
                    <div
                      className="marquee-slider"
                      style={{ background: marquee?.color }}
                    >
                      <div className="marquee-slide">
                        <div className="marquee-img">
                          <img
                            src={marquee.images}
                            width={"50"}
                            height={"50"}
                            alt="marquee"
                            loading="lazy"
                          />
                        </div>
                        <ul>
                          <li className="industries-name">
                            {marquee.industries}
                          </li>
                          <li className="about-industries">{marquee.about}</li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </Marquee>
            );
          })}
        </div>
      </section>

      <section className="web-development-needs ar-needs">
        <div className="container">
          <h2 className="text-center">
            Why Choose Arccus Inc. For AR/VR App Development?
          </h2>
          <div className="row">
            <div className="col-md-6">
              <div className="ar-overview">
                <div className="overview-block top-block mt-0">
                  <div className="overview-item">
                    <h3>
                      10<span>+</span>
                    </h3>
                    <p>Years of Experience</p>
                  </div>
                  <div className="overview-item">
                    <h3>
                      15<span>+</span>
                    </h3>
                    <p>Tech Professionals</p>
                  </div>
                </div>
                <div className="overview-block justify-content-center">
                  <div className="overview-item">
                    <h3>
                      98<span>%</span>
                    </h3>
                    <p>Customer Retention</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 Collaborators">
              <p>
                In the fast-changing digital landscape, AR and VR offer
                significant opportunities for businesses. However, success isn’t
                just about quick implementation, it's about delivering real
                value.
              </p>
              <p>
                To stand out, companies must create unique experiences that
                seamlessly integrate the physical and digital worlds. This often
                requires specialized expertise that may not be available
                in-house. That’s where Arccus Inc. steps in.
              </p>
              <p>
                As a leading AR/VR development company, we focus on crafting
                tailored solutions that boost user engagement and achieve
                measurable business results. Our talented team combines advanced
                technology with creative design to develop immersive
                applications that resonate with users across various industries.
              </p>
              <p>
                We prioritize maximizing your ROI through strategic development
                and a thorough understanding of your goals. Whether you're
                aiming to enhance training programs, launch innovative marketing
                initiatives, or develop interactive games, we have the expertise
                to help you thrive.
              </p>
              <Link to="/contact-us" className=" quote mt-4 d-inline-block">
                Contact us today
              </Link>
            </div>
          </div>
        </div>
      </section>

      <ARDevlopmentFaq />
    </div>
  );
}

export default ARVR;
