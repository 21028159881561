import React from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

export default () => {
  const Faq = [
    {
      que: "How long does it take to develop a custom website app?",
      ans: "The timeline for developing a custom website app typically ranges from 6 to 12 weeks, depending on the project's complexity and specific features required. We prioritize thorough planning and testing to ensure a high-quality end product.",
    },
    {
      que: "Do you offer ongoing support and maintenance?",
      ans: "Yes, we offer comprehensive ongoing support and maintenance services to keep your website running smoothly. Our team is available to address any issues, implement updates, and make enhancements as your business evolves.",
    },
    {
      que: "What is the average cost for website development?",
      ans: "The average cost for website development can vary significantly based on the project's scope, features, and complexity. We provide detailed estimates to ensure transparency and alignment with your budget.",
    },
    {
      que: "Can you work with my existing website?",
      ans: "Absolutely! We can integrate new features, enhance functionality, and optimize your existing website to improve performance and user experience. Our team will assess your current site to recommend the best enhancements.",
    },
  ];

  const FaqRight = [
    {
      que: "What is a progressive web app?",
      ans: "A progressive web app (PWA) is a web application that offers an app-like experience through the browser, providing offline capabilities, fast loading times, and seamless user engagement across all devices. PWAs combine the benefits of both web and mobile applications.",
    },
    {
      que: "How long does it take to build a web solution?",
      ans: "Building a complete web solution generally takes 8 to 16 weeks, depending on the complexity and requirements of the project. We focus on delivering a robust solution that meets your business needs while adhering to a structured timeline.",
    },
    {
      que: "What programming languages does Intellectsoft use to build apps?",
      ans: "We use a variety of programming languages to build apps, including JavaScript, Python, Ruby, and PHP. Our choice of technology is tailored to meet the specific needs and goals of each project, ensuring optimal performance and scalability.",
    }
  ];

  return (
    <section className="wd-faq">
      <div className="container">
        <h2 className="">
          Frequently Asked <span className="lastword">Questions</span> (FAQ)
        </h2>
        <div className="faq-block">
          <MDBAccordion initialActive={1}>
          {Faq.map( (faq,index) => (
            <MDBAccordionItem collapseId={index + 1} headerTitle={faq.que}>
              {faq.ans}
            </MDBAccordionItem>
           ))} 
          </MDBAccordion>

          <MDBAccordion>
          {FaqRight.map( (faq,index) => (
            <MDBAccordionItem collapseId={index + 1} headerTitle={faq.que}>
              {faq.ans}
            </MDBAccordionItem>
           ))} 
          </MDBAccordion>
        </div>
      </div>
    </section>
  );
};
