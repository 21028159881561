import "./App.css";
import { useEffect } from "react";
import { scrollToTop } from "./App.js";
function About() {
  useEffect(() => {
    scrollToTop();
  }, []);
  const team = [
    {
        image: "./assets/team/1.jpg",
        name : "Kent Baltare",
        position: "VP OF OPERATIONS",
    },
    {
        image: "./assets/team/2.jpg",
        name : "Phi Van Phan",
        position: "HEAD OF PRODUCT & MARKETING",
    },
    {
        image: "./assets/team/3.jpg",
        name : "Christian Santacana",
        position: "HEAD OF SALES",
    },
    {
        image: "./assets/team/4.jpg",
        name : "Anne Brask",
        position: "DIRECTOR OF PLANNING",
    },
    {
        image: "./assets/team/5.jpg",
        name : "Will Sperry",
        position: "GENERAL MANAGER OF CA",
    },
    {
        image: "./assets/team/6.jpg",
        name : "Jesse Iborra",
        position: "CUSTOMER SUCCESS MANAGER",
    },
    {
        image: "./assets/team/7.jpg",
        name : "Skyler Sexton",
        position: "SENIOR MANAGER, PROJECT OPERATIONS",
    },
    {
        image: "./assets/team/8.jpg",
        name : "Dan Monteverde",
        position: "SENIOR PROJECT MANAGER",
    },
    {
        image: "./assets/team/9.jpg",
        name : "Dan Monteverde",
        position: "SENIOR PROJECT MANAGER",
    },
  ]
  return (
    <div className="App banner-sec about-us-new">
      <div className="container">
        <h1>​The Abodu Vision & Mission</h1>
      </div>
      <div className="large-image">
        <img src="https://www.abodu.com/_ipx/w_1920,q_75/https%3A%2F%2Fres.cloudinary.com%2Fdxkibqymo%2Fimage%2Fupload%2Fq_auto%2Fc_scale%2Cf_auto%2Cw_1600%2Fv1677267883%2FAll%2520Approved%2520Images%2F9b_AboduOne_CedarVerticalSiding_RedwoodCity_llkicq.jpg?url=https%3A%2F%2Fres.cloudinary.com%2Fdxkibqymo%2Fimage%2Fupload%2Fq_auto%2Fc_scale%2Cf_auto%2Cw_1600%2Fv1677267883%2FAll%2520Approved%2520Images%2F9b_AboduOne_CedarVerticalSiding_RedwoodCity_llkicq.jpg&w=1920&q=75" />
      </div>
      <div className="container">
        <div className="centered-content">
          <h2>Backyard homes for lives in progress.</h2>
          <p>
            At Abodu, we’re inspiring homeowners to reimagine the potential of
            every backyard, with beautiful, flexible spaces that adapt to how
            you live. Host guests. Home office. Extra income. Family for the
            holidays. Or grandparents, for good. Every Abodu revolves around you
            — for now, for what’s next, and for long into the future.
          </p>
        </div>
        <div className="spacer"></div>
        <div className="centered-content">
          <h2>Backyard homes for lives in progress.</h2>
          <p>
            At Abodu, we’re inspiring homeowners to reimagine the potential of
            every backyard, with beautiful, flexible spaces that adapt to how
            you live. Host guests. Home office. Extra income. Family for the
            holidays. Or grandparents, for good. Every Abodu revolves around you
            — for now, for what’s next, and for long into the future.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row about-company">
          <div className="col-6">
            <div className="image-company-profile">
              <img src="https://www.abodu.com/_ipx/w_1920,q_25/https%3A%2F%2Fres.cloudinary.com%2Fdxkibqymo%2Fimage%2Fupload%2Ft_Facebook%2520ad%2Fv1691183557%2FTeam%2520headshots%2FJohn%2520Geary.jpg?url=https%3A%2F%2Fres.cloudinary.com%2Fdxkibqymo%2Fimage%2Fupload%2Ft_Facebook%2520ad%2Fv1691183557%2FTeam%2520headshots%2FJohn%2520Geary.jpg&w=1920&q=25" />
            </div>
            <h3>CO-FOUNDER & CEO</h3>
            <h4>John Geary</h4>
            <p>
              John Geary is Co-Founder and CEO of Abodu, the ADU company. Abodu
              builds backyard homes (also called Accessory Dwelling Units or
              ADUs) that expand a property's livable space. John co-founded
              Abodu to streamline the process of installing an ADU, and has
              created an innovative concierge service that project manages the
              entire process of building an ADU from permitting to installation,
              in as little as 9 months. Prior to founding Abodu, John spent over
              three years at Bain and Company, a global management consulting
              firm, where he worked as a real estate strategist to Fortune 500
              company executives, and a consumer products consultant.
            </p>
          </div>
          <div className="col-6">
            <div className="image-company-profile">
              <img src="https://www.abodu.com/_ipx/w_1920,q_25/https%3A%2F%2Fres.cloudinary.com%2Fdxkibqymo%2Fimage%2Fupload%2Fc_fill%2Cf_auto%2Cg_face%2Ch_800%2Cw_800%2Cx_0%2Cy_0%2Cz_1%2Fv1691183553%2FTeam%2520headshots%2FEric%2520McInerney.jpg?url=https%3A%2F%2Fres.cloudinary.com%2Fdxkibqymo%2Fimage%2Fupload%2Fc_fill%2Cf_auto%2Cg_face%2Ch_800%2Cw_800%2Cx_0%2Cy_0%2Cz_1%2Fv1691183553%2FTeam%2520headshots%2FEric%2520McInerney.jpg&w=1920&q=25" />
            </div>
            <h3>CO-FOUNDER & CEO</h3>
            <h4>John Geary</h4>
            <p>
              John Geary is Co-Founder and CEO of Abodu, the ADU company. Abodu
              builds backyard homes (also called Accessory Dwelling Units or
              ADUs) that expand a property's livable space. John co-founded
              Abodu to streamline the process of installing an ADU, and has
              created an innovative concierge service that project manages the
              entire process of building an ADU from permitting to installation,
              in as little as 9 months. Prior to founding Abodu, John spent over
              three years at Bain and Company, a global management consulting
              firm, where he worked as a real estate strategist to Fortune 500
              company executives, and a consumer products consultant.
            </p>
          </div>
        </div>
      </div>
      <div className="join-team">
        <div className="container">
          <h5>Want to join the team?</h5>
          <p>
            Our team brings the best of both worlds – real estate and
            construction veterans teamed up with experts across several
            industries.
          </p>
          <button className="quote w-auto">See available positions</button>
        </div>
      </div>
      <div className="company-team">
        <div className="container">
          <div className="row">
          {team.map((items) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <div className="team-profile">
                <img src={items.image} />
              </div>
              <span>{items.position}</span>
              <h6>{items.name}</h6>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
