
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import client from "./client.json";
import clientright from "./assets/client.webp"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default () => {

 

  return (
   
    <Swiper breakpoints={{
      0: {
        slidesPerView: 1.2,
      },
      400:{
        slidesPerView:1.2,
      },
      639: {
        slidesPerView: 1.3,
      },
      865:{
        slidesPerView:2.5
      },
      1000:{
        slidesPerView:3.1
      },
      1500:{
        slidesPerView:3.5
      },
      1700:{
        slidesPerView:3.5
      }
    }}

      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={16}
      slidesPerView={3.5}
      navigation
     
     
    >
     {client?.map( client => {
        return(
      <SwiperSlide>
        <div className='client-slider'>
            <div className='client-img'>
              
                <h4>{client.head}</h4>
                <img src={clientright} width={"53"} height={"43"} alt={"clientright"}  />
            </div>
            <p className='client-feedback'>{client.feedback}</p>
            <ul>
                <li className='client-name'>{client.name}</li>
                <li className='client-occupation'>{client.occupation}</li>
            </ul>
        </div>
      </SwiperSlide>
      )})} 
      
    </Swiper>
  );
};