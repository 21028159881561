
import './App.css';
import React,{useEffect} from "react";
import { useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from './assets/iwSpAPAJCh.json'
import job from './job.json';
import Job from './assets/job.webp';
import Experience from './assets/experience.webp';
import Position from './assets/position.webp';
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";

function Apply() {
  useEffect(() => {
    
    scrollToTop();
 
  }, [])
  // const url = "https://dummyjson.com/products"
  // const [data, setData] = useState({
  //   name : "",
  //   email : "",
  //   phone : "",
  //   currentCTC : "",
  //   expectedCTC : "",
  //   currentEmployee : "",
  //   relevantExperiance : "",
  //   resume : "",
  //   message : ""
  // })

  // function submit(e) {
  //   e.preventDefault();
  //   Axios.post(
  //     url, {
  //       name: data.name,
  //       email: data.email,
  //       phone: data.phone,
  //       currentCTC : data.currentCTC,
  //       expectedCTC : data.expectedCTC,
  //       currentEmployee: data.currentEmployee,
  //       relevantExperiance: data.relevantExperiance,
  //       resume : data.resume,
  //       message : data.message,
  //     })
  //         .then(res => {
  //           console.log(res.data)
  //         })
  // }
  

  // function handle(e) {
  //   const newdata = { ...data }
  //   newdata[e.target.id] = e.target.value
  //   setData(newdata)
  //   console.log(newdata)
  // }
 
  const location = useLocation()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
   
    <div className="App">
   
    <MetaTags>
            
            <meta name="description" content="Technical Business Development Executive with a minimum of 3 years of experience as a BDE in an IT farm with IT or management background." />
            <meta name="keyword" content="Mobile App Development, Hire Laravel Developer, Hire Codeigniter, Developer, Hire Javascript Developer, app web developer, hire web developer, ui ux web developer, web design and web developer, web development services, Web Engineering, Mobility, Digital Marketing, Game Developing, Strategic Consulting, Web CMS And Portal Development, e-Commerce Development, social media marketing agency" />
            <meta property="og:title" content="Apply" />
            <link rel="canonical" href="https://arccusinc.com/apply" />
            <title>Apply | Arccus Inc</title>
      </MetaTags>
    
    <section className='apply banner-sec '>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <h5>YOU CAN</h5>
                    <h2 className='text-start'>Schedule Interview for the position of <span className='lastword'>{location?.state?.job?? ""}</span></h2>
                    <ul>
                      <li>
                        <img src={Job} alt={"job"} />
                        <p>{location?.state?.Duration?? ""}</p>
                        <p> Job Type</p>
                      </li>
                      <li>
                        <img src={Experience} alt={"Experience"} />
                        <p>{location?.state?.experince?? ""}</p>
                        <p>Total Experience</p>
                      </li>
                      <li>
                        <img src={Position} alt={"Position"} />
                        <p>{location?.state?.position?? ""}</p>
                        <p>Position</p>
                      </li>
                    </ul>
                </div>
                <div className='col-md-6'>
                <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />
                    
                </div>
            </div>
        </div>
    </section>

    <section className='Apply-form'>
        <div className='container'>
            <h2>Schedule Your Interview <span className='lastword'>@ Arccus Inc.</span></h2>
            <div className='row'>
                <div className='col-md-6'>
                    {/* <form onSubmit={(e)=> submit(e)}>
                      <h3>Apply Now</h3>
                       <div className="input-first-row">
                          <div className='w-100'>
                            <label for="">Name <span className="mendotry-field">*</span></label>
                            <input onChange={(e) => handle(e)} id="name" value={data.name} type="text" pattern="[A-Z]{}" required></input>
                          </div>
                        </div>
                      <div className="input-first-row">
                          <div className='w-100'>
                              <label for="">Email <span className="mendotry-field">*</span></label>
                              <input onChange={(e) => handle(e)} id="email" value={data.email} type="email" required></input>
                          </div>
                          <div className='w-100'>
                                <label for="">Phone <span className="mendotry-field">*</span></label>
                                <input onChange={(e) => handle(e)} id="phone" value={data.phone} type="number" required pattern="[0-9]{3}[0-9]{3}[0-9]{4}"></input>
                          </div>
                        </div>
                        <div className="input-first-row">
                          <div className='w-100'>
                            <label for="">Current CTC <span className="mendotry-field">*</span></label>
                            <input onChange={(e) => handle(e)} id="currentCTC" value={data.currentCTC} type="number"  required></input>
                          </div>
                          <div className='w-100'>
                            <label for="">Expected CTC <span className="mendotry-field">*</span></label>
                            <input onChange={(e) => handle(e)} id="expectedCTC" value={data.expectedCTC} type="number"  required></input>
                          </div>
                        </div>
                        <div className="input-first-row">
                          <div className='w-100'>
                            <label for="">Current Employee <span className="mendotry-field">*</span></label>
                            <input onChange={(e) => handle(e)} id="currentEmployee" value={data.currentEmployee} type="text" pattern="[A-Z]{}" required></input>
                          </div>
                          <div className='w-100'>
                            <label for="">Relevant Experiance <span className="mendotry-field">*</span></label>
                            <select onChange={(e) => handle(e)} id="relevantExperiance" value={data.relevantExperiance} required>
                                <option value="0">Fresher</option>
                                <option value="1"> 2 &#10094; years </option>
                                <option value="2">3 to 5 years</option>
                                <option value="3">6 &#10095; years</option>
                            </select>
                          </div>
                        </div>
                        <div className="input-first-row">
                          <div className='w-100'>
                            <label for="">Resume <span className="mendotry-field">*</span></label>
                            <input onChange={(e) => handle(e)} id="resume" value={data.resume} type="file" required></input>
                          </div>
                        </div>
                        <div className="input-first-row">
                          <div className='w-100'>
                            <label for="">Your Message </label>
                            <input onChange={(e) => handle(e)} id="message" value={data.message} type="text" required></input>
                          </div>
                        </div>
                        <ReCAPTCHA
                          sitekey="Your client site key"
                          onChange={onChange}
                        />
                        <button className='quote' type="submit">Apply</button>
                    </form> */}
                    <iframe title='apply' src='https://tally.so/embed/mDp1jR?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1' width="100%" ></iframe>
                </div>
                <div className='col-md-6 job-releted-data'>
                    <h6>Job Description</h6>
                    <p>
                      {location?.state?.discription?? ""}
                    </p>
                    <h6>Skills:</h6>
                    <ul>{location?.state?.Skills?.map(ps=> {
                    return(
                    <li>{ps}</li>
                        )
                    })}
                      
                    </ul>
                    <h6>Job Responsibility:</h6>
                    <ul>
                    {location?.state?.Responsibility?.map(ps=> {
                    return(
                    <li>{ps}</li>
                        )
                    })}
                    </ul>
                </div>
            </div>
        </div>
    </section>
   
   </div> 
    
  );
}

export default Apply;

