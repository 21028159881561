
import React from "react";

export default () => {


  return (
   
    <section className="contact-form">
        <div className="container">
            <div className="contact-form-detail row">
                <div className="col-md-6">
                <h2 className="text-start mb-3 pb-0">
                    Interested in working <span className="lastword">with us?</span>
                </h2>
                <p>Let's talk and get started</p>
                <h3>What you will get:</h3>
                <ul>
                    <li>On-call inquiry assistance</li>
                    <li>Project consulting by experts</li>
                    <li>Detailed project estimation</li>
                </ul>
                </div>
                <div className="col-md-6">
                    <div className="contact-form-iframe">
                        <iframe title="contact" style={{width: '100%', height: '1062px'}} className="my-form" src="https://tally.so/embed/3xDX7J?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};