import React from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

export default () => {
  const Faq = [
    {
      que: "What industries can benefit from AR/VR development?",
      ans: "We have enhanced industries such as healthcare, education, entertainment, retail, real estate, and manufacturing. Our AR/VR solutions support training simulations, immersive learning, virtual tours, and interactive marketing, delivering significant improvements in engagement and efficiency.",
    },
    {
      que: "Can you integrate AR/VR features into existing apps?",
      ans: "Yes, we can seamlessly integrate AR/VR features into your existing applications. Our team conducts a thorough assessment of your current app to pick the best approach for incorporating these immersive technologies, enhancing user experience and functionality.",
    },
    {
      que: "What makes Arccus Inc. a leading AR/VR development company?",
      ans: "Stand out as a top AR/VR development company thanks to our skilled team and innovative approach. We deliver tailored solutions that enhance user engagement and drive results. Our commitment to quality and client collaboration sets us apart in the industry.",
    }
  ];

  const FaqRight = [
    {
      que: "How long does it take to develop an AR/VR app?",
      ans: "The development timeline for an AR/VR app can vary based on complexity and specific features. Generally, it can take anywhere from a few weeks to several months. We work closely with clients to establish a timeline that aligns with their project needs and goals.",
    },
    {
      que: "What is the cost of developing an AR/VR app?",
      ans: "The cost of developing an AR/VR app depends on various factors, including the app’s complexity, the technology stack used, and the project’s scope. We’ll work with you to create a budget that meets your needs.",
    },
    {
      que: "What is your experience in AR and VR development?",
      ans: "We have over 10 years of experience in AR and VR development, creating tailored solutions for diverse industries. Our expert team specializes in delivering immersive applications that enhance user engagement. We stay at the cutting edge of technology to ensure our clients achieve their goals.",
    }
  ];

  return (
    <section className="wd-faq ar-faq">
      <div className="container">
        <h2 className="">
          Frequently Asked Questions(FAQ)
        </h2>
        <div className="faq-block">
          <MDBAccordion initialActive={1}>
          {Faq.map( (faq,index) => (
            <MDBAccordionItem collapseId={index + 1} headerTitle={faq.que}>
              {faq.ans}
            </MDBAccordionItem>
           ))} 
          </MDBAccordion>

          <MDBAccordion>
          {FaqRight.map( (faq,index) => (
            <MDBAccordionItem collapseId={index + 1} headerTitle={faq.que}>
              {faq.ans}
            </MDBAccordionItem>
           ))} 
          </MDBAccordion>
        </div>
      </div>
    </section>
  );
};
