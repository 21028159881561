import React from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

export default () => {
  const Faq = [
    {
      que: "How long does mobile app development take?",
      ans: "The timeline varies based on complexity, but typically ranges from a few weeks to several months.",
    },
    {
      que: "What mobile app development software do you use?",
      ans: "We use a variety of tools and frameworks, including React Native, Flutter, and native development environments for iOS and Android.",
    },
    {
      que: "Can cross-platform apps offer the same performance as native apps?",
      ans: "Cross-platform apps can deliver strong performance, but native apps typically offer better speed and access to device features. The choice depends on your app's specific needs.",
    },
    {
      que: " How do you manage the mobile app development process?",
      ans: "We follow an agile process, involving regular updates and collaboration with clients throughout the development cycle.",
    },
    {
      que: "How do we ensure mobile apps work well on all devices and screen sizes?",
      ans: "We use responsive design principles and extensive testing across various devices to ensure compatibility and optimal performance.",
    }
  ];

  const FaqRight = [
    {
      que: "Do you provide support after the app is launched?",
      ans: "Yes, we offer ongoing support and maintenance to ensure your app runs smoothly post-launch.",
    },
    {
      que: "Can you develop cross-platform apps?",
      ans: "Absolutely! We specialize in developing robust cross-platform applications.",
    },
    {
      que: "What is the difference between cross-platform and native mobile app development?",
      ans: "Native apps are built specifically for one platform (iOS or Android), while cross-platform apps are designed to run on multiple platforms using a single codebase.",
    },
    {
      que: "How can an enterprise mobile application stay secure?",
      ans: "We implement best practices such as data encryption, secure authentication, and regular security audits to keep apps secure.",
    }
  ];

  return (
    <section className="wd-faq">
      <div className="container">
        <h2 className="">
          Frequently Asked <span className="lastword">Questions</span> (FAQ)
        </h2>
        <div className="faq-block">
          <MDBAccordion initialActive={1}>
          {Faq.map( (faq,index) => (
            <MDBAccordionItem collapseId={index + 1} headerTitle={faq.que}>
              {faq.ans}
            </MDBAccordionItem>
           ))} 
          </MDBAccordion>

          <MDBAccordion>
          {FaqRight.map( (faq,index) => (
            <MDBAccordionItem collapseId={index + 1} headerTitle={faq.que}>
              {faq.ans}
            </MDBAccordionItem>
           ))} 
          </MDBAccordion>
        </div>
      </div>
    </section>
  );
};
