import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import logo from "./assets/logo.webp";
import { Link } from "react-router-dom";
import officelocation from './officelocation.json';

export default function App() {
  return (
    <MDBFooter className="text-center text-lg-start ">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom border-top">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>

        <div className="social-icon">
          <a
            href="https://www.instagram.com/arccusinc/"
            target="_blank"
            className="me-4 text-reset"
            aria-label="instagram"
          >
            <MDBIcon fab icon="instagram" />
          </a>

          <a
            href="https://www.facebook.com/arccusinc"
            target="_blank"
            className="me-4 text-reset"
            aria-label="facebook-square"
          >
            <MDBIcon fab icon="facebook-square" />
          </a>

          <a
            href="https://www.linkedin.com/company/arccus-inc/"
            target="_blank"
            className="me-4 text-reset"
            aria-label="linkedin"
          >
            <MDBIcon fab icon="linkedin" />
          </a>

          <a
            href="https://x.com/ArccusInc"
            target="_blank"
            className="me-4 text-reset"
            aria-label="twitter"
          >
            <MDBIcon fab icon="twitter" />
          </a>
        </div>
      </section>

      <section className="footer">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-4">
              <h6 className="logo fw-bold mb-4">
                <img src={logo} alt={"logo"} width={"160"} height={"50"} />
              </h6>
              <p>
                Arccus Inc., a digital solutions leader, offers web and mobile
                app development, IT consulting, and AR/VR services, delivering
                tailored tech solutions for businesses of all sizes.
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Why Arccus</h6>
              <p>
                <a href="#!" className="text-reset">
                  <Link to="about-us">About Us</Link>
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  <Link to="career">Career</Link>
                </a>
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Services</h6>

              <p>
                <a href="#!" className="text-reset">
                  <Link to="web-development">Web App Development</Link>
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  <Link to="mobile-app-development">
                    Mobile App Development
                  </Link>
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  <Link to="ar-vr-development">AR VR Development</Link>
                </a>
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Learn more</h6>
              <p>
                <a href="#!" className="text-reset">
                  <Link to="faq">FAQ</Link>
                </a>
              </p>
              <p>
                <a href="blog" className="text-reset">
                  Blog
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  <Link to="contact-us">Contact Us</Link>
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  <Link to="privacy-policy">Privacy policy</Link>
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              {/* <p>
                Sign up to receive our newsletter for instant access to
                insights.
              </p>
              <Link to="contact-us" className="quote">
                Consult Now!
              </Link> */}
              <div>
              {officelocation.map( officelocation => {
                return(
                <div>
                  <p className="mb-0"><b>{officelocation.location}</b></p>
                  <p className="mb-0">{officelocation.address}</p>
                  <p>{officelocation.contact}</p>
                </div>
              )})} 
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-2"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        Copyright © {new Date().getFullYear()} Arccus Inc. All rights reserved.
      </div>
    </MDBFooter>
  );
}
