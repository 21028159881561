import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from "react-router-dom";

export default () => {

const caseStudies = [
  {
    image: "../assets/project/CaseStudies1.png",
    title: "Transforming Events into an Immersive Digital Experience",
    tag: ["UI UX Design","App Development"],
    text: "Step into the future of trade shows with our Virtual Expo App for all devices!",
    link: "#"
  },
  {
    image: "../assets/project/CaseStudies1.png",
    title: "AI-Powered Assistant Revolutionizing Real Estate Efficiency",
    tag: ["UI UX Design","App Development"],
    text: "The AI assistant for real estate, automating contracts and contacts at your command!",
    link: "#"
  }
]
  return (
   
  <section className="case-studies">
    <div className="container">
      <h2 className="text-center mb-3 pb-0">
        Case <span className="lastword">Studies</span>
      </h2>
      <p className="text-center">
        Explore our web development case studies to see how we build
        impactful, user-friendly websites that achieve real results. Each
        project highlights our strategies for tackling challenges, using the
        latest technologies, and providing customized solutions for our
        clients. From e-commerce sites to dynamic corporate web pages, our
        portfolio demonstrates our dedication to quality and innovation.
      </p>
      <div className="case-studies-slider">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={16}
          slidesPerView={1}
          navigation
        >
        {caseStudies?.map( item => {
          return(
          <SwiperSlide>
            <div className="case-item">
              <div className="row">
                <div className="col-md-7">
                  <img src={item.image} alt="case-slide" />
                </div>
                <div className="col-md-5 d-flex flex-column justify-content-between">
                  <div>
                    <h3>
                      {item.title}
                    </h3>
                    <ul>
                    {item?.tag?.map(
                          projectType => {
                              return(
                      <li>{projectType}</li>
                    )})}
                    </ul>
                    <p>
                      {item.text}
                    </p>
                  </div>
                  <Link
                    to={item.link}
                    className="quote"
                    style={{ width: "fit-content" }}
                  >
                    View Case Studies
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          )})}
        </Swiper>
      </div>
    </div>
  </section>
  );
};