
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import portfolio from "./portfolio.json";


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default () => {

  // useEffect(() => {
  //   getClientapi()
  // }, [])

  // const [client, setClient] = useState([]);
  // const getClientapi = () => {

  //   fetch("https://mocki.io/v1/5b940897-079d-4ade-a9cf-08df623659bf")
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data => {
  //       console.log("=========", data)
  //       setClient(data)
  //     })
  // }

  return (
   
    <Swiper breakpoints={{
      0: {
        slidesPerView: 1.2,
        spaceBetween:10
      },
      400:{
        slidesPerView:1.5,
        spaceBetween:20
      },
      639: {
        slidesPerView: 2,
        spaceBetween:20
      },
      865:{
        slidesPerView:2,
        spaceBetween:30
      },
      1000:{
        slidesPerView:3,
        spaceBetween:30
      },
      1500:{
        slidesPerView:3,
        spaceBetween:50
      },
      1700:{
        slidesPerView:3,
        spaceBetween:50
      }
    }}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3.5}
      navigation
    >
     {portfolio?.map( client => {
        return(
      <SwiperSlide>
        <div className='portfolio-slider'>
           <div className='portfolio-card'>
                <img src={client.portfolio} alt='portfolio-img'/>
           </div>
           <div className='portfolio-detail'>
                <h6>{client.title}</h6>
                <p>{client.content}</p>
           </div>
        </div>
      </SwiperSlide>
      )})} 
      
    </Swiper>
  );
};